// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blanco-y-negro-js": () => import("./../../../src/pages/blanco-y-negro.js" /* webpackChunkName: "component---src-pages-blanco-y-negro-js" */),
  "component---src-pages-eventos-js": () => import("./../../../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mascotas-js": () => import("./../../../src/pages/mascotas.js" /* webpackChunkName: "component---src-pages-mascotas-js" */),
  "component---src-pages-naturaleza-js": () => import("./../../../src/pages/naturaleza.js" /* webpackChunkName: "component---src-pages-naturaleza-js" */),
  "component---src-pages-retratos-js": () => import("./../../../src/pages/retratos.js" /* webpackChunkName: "component---src-pages-retratos-js" */)
}

